.card-type-title{
    background: rgb(255, 0, 0);
    position: absolute;
    padding: 2px 8px ;
    border-radius: 10px 0px 0px 10px;
    right: 0;
    top: 10px;

}
.card__cover{
position: relative;
}
 